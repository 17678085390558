@keyframes openModal {
  from {
    opacity: 0;
    clip-path: inset(100% 100% 100% 100%);
  }
  to {
    opacity: 1;
    clip-path: inset(0px 0px 0px 0px);
  }
}

.ReactModal__Content {
  width: 100%;

  animation: openModal 0.4s;
}

@media print {
  .ReactModalPortal {
    display: none;
  }
}

.ReactModal__Overlay {
  z-index: 9998;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}